<template>
  <a-spin tip="加载中..." :spinning="loading" style="width: 100%">
    <h1>{{ formModel.title }}</h1>
    <p class="date">生效日期：{{ formModel.date }}</p>
    <div class="main-content" v-html="formModel.content"></div>
  </a-spin>
</template>
<script>
export default {
  props: {
    type: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formModel: {
        title: "",
        date: "",
        content: "",
      },
      loading: false,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.loading = true;
      this.api.service
        .fpc_oapi_agreement_selectPublished({}, { agreementType: this.type })
        .then(async (res) => {
          await this.api.toast(res, 0);
          const { agreementTitle, agreementContent, publishTime } = res.data;
          this.formModel.title = agreementTitle;
          this.formModel.content = agreementContent;
          this.formModel.date = publishTime;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
h1 {
  font-family: PingFangSC-Medium;
  font-size: 36px;
  color: #000000;
  letter-spacing: 0;
  font-weight: 500;
}

.date {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #666666;
  padding-bottom: 15px;
  border-bottom: 1px solid #f0f0f0;
}
</style>